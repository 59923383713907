.bg-solution {
    background: #0B0A33;
}

.single-solution-style-one {
    padding: 48px;
    border-right: 1px solid #454545;
    border-top: 1px solid #454545;
    position: relative;
    transition: .3s;

    @media #{$smlg-device} {
        border: none !important;
        padding: 15px !important;
    }

    @media #{$large-mobile} {
        padding: 15px !important;
    }

    .right-draw {
        position: absolute;
        right: 0;
        top: 0;
        opacity: 0;

        img {
            transition: .3s;
        }
    }

    &:hover {
        background: #fff;

        .icon {
            img {
                filter: brightness(0) saturate(100%) invert(20%) sepia(100%) saturate(5128%) hue-rotate(243deg) brightness(98%) contrast(106%);
            }
        }

        .title {
            color: #000000;
        }

        p.disc {
            color: #000000;
        }

        .right-draw {
            opacity: 1;

            img {
                animation: moveLeft .5s linear;
            }
        }

        .btn-arrow {
            color: #6D6D6D;
        }
    }

    &.border-left {
        border-left: 1px solid #454545;
    }

    &.border-bottom-1 {
        border-bottom: 1px solid #454545;
    }

    .title {
        color: #fff;
        margin-top: 40px;
        transition: .3s;
    }

    p.disc {
        color: #FFFFFF;
        margin-bottom: 25px;
        transition: .3s;
    }

    .btn-arrow {
        color: #B0B0B0;
        transition: .3s;
        font-weight: 500;
        font-size: 16px;

        img {
            transition: .3s;
        }

        svg {
            transition: .3s;
        }


        &:hover {
            color: var(--color-primary);

            img {
                margin-left: 5px;
                filter: brightness(0) saturate(100%) invert(20%) sepia(100%) saturate(5128%) hue-rotate(243deg) brightness(98%) contrast(106%);
            }

            svg {
                margin-left: 5px;

                path {
                    stroke: var(--color-primary);
                }
            }
        }
    }
}

.title-center-2 {
    text-align: center;
    max-width: 952px;
    margin: auto;

    .title {
        font-size: 64px;
        color: #fff;
        font-weight: 400;

        @media #{$mdsm-layout} {
            font-size: 44px;
        }

        @media #{$large-mobile} {
            font-size: 36px;
        }
    }

    p {
        max-width: 80%;
        margin: auto;
        color: #fff;
        font-size: 20px;
        line-height: 1.5;

        @media #{$large-mobile} {
            max-width: 100%;
        }
    }
}

.solution-expertice-area {
    .rts-btn {
        background: #fff;
        color: #000;
        margin: auto;
        margin-top: 80px;
        border: 1px solid transparent;

        img {
            filter: brightness(0) saturate(100%) invert(0%) sepia(12%) saturate(202%) hue-rotate(343deg) brightness(84%) contrast(100%);
        }

        svg {
            max-width: 24px;

            path {
                stroke: #2A2ACC !important;
            }
        }

        &:hover {
            svg {
                path {
                    stroke: #2A2ACC !important;
                }
            }
        }
    }
}

.text-center-title-bg-white {
    text-align: center;

    .title {
        font-size: 48px;

        @media #{$large-mobile} {
            font-size: 34px !important;
        }
    }

    p {
        max-width: 60%;
        margin: auto;
        font-size: 20px;

        @media #{$sm-layout} {
            max-width: 100%;
        }

        @media #{$large-mobile} {
            max-width: 100%;
            font-size: 16px;
        }
    }
}

.solution-expertice-area {
    position: relative;

    .top-left {
        position: absolute;
        left: 120px;
        top: -24px;
    }
}

.container-full {
    max-width: 1920px;
    margin: auto;
}

.case-studies-area {
    .shape-left-top {
        position: absolute;
        left: 0;
        top: 0;

        @media #{$large-mobile} {
            display: none;
        }
    }

    .shape-bottom {
        position: absolute;
        right: 0;
        bottom: 0;

        @media #{$large-mobile} {
            display: none;
        }
    }
}

.solution-exparts-area-service-page .exparts-area-main-mt-dec {
    position: relative;

    .top-right-shape {
        position: absolute;
        right: 0;
        top: 0;

        @media #{$mdsm-layout} {
            display: none;
        }
    }
}