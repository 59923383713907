.bg-gradient-pricing {
    background: linear-gradient(180deg, #F6F6F6 0%, rgba(255, 255, 255, 0) 100%);
    position: relative;
    z-index: 1;

    .bg-shape-area {
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        z-index: -1;
        min-width: 70%;
    }
}

.m-auto {
    margin: auto;
    text-align: center;
    font-size: 14px;
}

.container-pricing {
    max-width: 768px;
    margin: auto;
}

.single-pricing-area {
    padding: 64px;
    border-right: 1px solid #D1D1D1;
    border-top: 1px solid #D1D1D1;
    border-bottom: 1px solid #D1D1D1;
    background: #FFFFFF;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    text-align: center;

    @media #{$sm-layout} {
        padding: 30px;
    }

    .head {
        margin-bottom: 30px;

        span {
            font-size: 28px;
            color: var(--color-primary);
            font-weight: 600;
            margin-bottom: 10px;
            display: block;

            @media #{$large-mobile} {
                font-family: var(--font-medium) !important;
                font-size: 22px;
            }
        }

        .title {
            font-size: 64px;
            margin-bottom: 5px;

            @media #{$sm-layout} {
                font-size: 42px;
            }

            @media #{$large-mobile} {
                font-size: 36px;
                margin-top: 10px;
            }
        }
    }

    .body {
        .rts-btn {
            width: 100%;
            z-index: 1;
            height: 48px;
            max-width: 100%;
            margin-bottom: 15px;

            &::after {
                height: 300px;
                width: 300px;
            }
        }
    }

    &.border-left {
        border-left: 1px solid #D1D1D1;
    }
}


.gradient-bg-pricing {

    background: linear-gradient(180deg, #E9F0FF 0%, rgba(255, 255, 255, 0) 100%);
}

.single-pricing-area-start-2 {
    border-top: 1px solid #D1D1D1;
    border-right: 1px solid #D1D1D1;
    border-bottom: 1px solid #D1D1D1;
    padding: 32px;

    @media #{$small-mobile} {
        padding: 25px 10px;
    }

    &.border-left {
        border-left: 1px solid #D1D1D1;
    }

    .head-area {
        text-align: center;

        .title {
            font-size: 64px;

            @media #{$smlg-device} {
                font-size: 46px;
            }

            @media #{$mdsm-layout} {
                font-size: 44px !important;
            }

            @media #{$large-mobile} {
                font-size: 40px !important;
            }
        }

        span {
            font-weight: 500;
        }

        .icon {
            height: 48px;
            width: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            margin: auto;
            background: #FFFFFF;
            box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);

        }

        p {
            margin-bottom: 17px;
            font-size: 20px;
            font-weight: 500;
            margin-top: 20px;
        }
    }

    .body-areas {
        margin-top: 30px;
        padding-bottom: 35px;

        .single-check {
            display: flex;
            align-items: center;
            gap: 15px;
            margin: 12px 0;

            p {
                margin-bottom: 0;
            }
        }
    }

    .footer-pricing {
        padding-top: 32px;
        border-top: 1px solid #D1D1D1;

        .rts-btn {
            max-width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 10px 16px;

            &::after {
                width: 400px;
                height: 400px;
            }
        }
    }

    &.active {
        position: relative;

        .tag {
            position: absolute;
            right: 20px;
            top: 20px;
            color: var(--color-primary);
            font-weight: 500;
        }

        .head-area {
            p {
                color: var(--color-primary);
            }
        }
    }
}

.faq-bottom-section-text {
    p {
        a {
            color: var(--color-primary) !important;
        }
    }
}

.service-banner-content-wrapper {
    .bread-plug {
        display: flex;
        align-items: center;
        gap: 7px;

        a {
            font-size: 20px;
        }

        i {
            color: #999999;
        }

        a.current {
            color: #999999;
        }
    }

    .title {
        font-size: 64px;
        line-height: 1;
        margin-top: 30px;
        margin-bottom: 25px;

        @media #{$laptop-device} {
            font-size: 54px;
            line-height: 1.3;
        }

        @media #{$smlg-device} {
            font-size: 54px;
        }

        @media #{$sm-layout} {
            font-size: 44px;
            line-height: 1.3;
        }

        @media #{$large-mobile} {
            line-height: 1.3;
            font-size: 44px;
        }

        @media #{$small-mobile} {
            font-size: 36px;
        }
    }

    p.disc {
        max-width: 640px;
    }
}