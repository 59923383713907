// header style
.header-wrapper-1 {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media #{$mdsm-layout} {
        padding: 20px 0;
        margin-bottom: 25px;
    }

    @media #{$large-mobile} {
        margin-bottom: 0;
    }

    .nav-area {
        @media #{$mdsm-layout} {
            display: none;
        }
    }
}

header .rts-btn {
    @media #{$large-mobile} {
        display: none;
    }
}

.header-style-one.style-two {
    border-bottom: 1px solid #D1D1D1;
}


@-webkit-keyframes stickyanimations {
    0% {
        -webkit-transform: translateY(-100px);
        transform: translateY(-100px);
    }

    100% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
    }
}

@keyframes stickyanimations {
    0% {
        -webkit-transform: translateY(-100px);
        transform: translateY(-100px);
    }

    100% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
    }
}

.header--sticky {
    z-index: 999;
    position: relative;
    width: 100%;
    background: transparent;
}

.header--sticky.sticky {
    position: fixed !important;
    width: 100%;
    -webkit-animation: stickyanimations 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: stickyanimations 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    top: 0;
    left: 0;
    right: 0;
    border-bottom: 1px solid #fff3;
    box-shadow: 0px 7px 18px #1810100d;
    background: #fff;
}

.header-style-one.header--sticky {
    display: none;
}

.header-style-one.header--sticky.sticky {
    display: block;
}

.header-style-one.style-two.header--sticky {
    display: none;
}

.header-style-one.style-two.header--sticky.sticky {
    display: block;
}

.header-style-one.style-two {
    border-bottom: 1px solid #D1D1D1;
    position: relative;
    z-index: 99;
}

.header-area-four {
    position: absolute;
    width: 100%;
    z-index: 99;

    .header-four-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media #{$mdsm-layout} {
            padding: 15px;
        }

        @media #{$sm-layout} {
            padding: 15px 10px;
        }

        .nav-area {
            ul {
                li {
                    &>a.nav-link {
                        color: #fff;
                    }
                }
            }
        }
    }

    &.sticky {
        background: #0B0A33;
    }
}


.header-area-four.style-five .header-four-wrapper .nav-area ul li>a.nav-link {
    color: #0B0A33;
}

.header-area-four.style-five.sticky {
    background: #ffffff;
}

.rts-banner-area-five {
    position: relative;
    z-index: 1;

    .round-shape-one {
        img {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            z-index: -1;
            top: 0;
            opacity: 1;
            width: 100%;
        }
    }

    // &::after {
    //     position: absolute;
    //     width: 250px;
    //     height: 250px;
    //     left: 667.51px;
    //     top: 82.76px;
    //     content: '';
    //     background: linear-gradient(270deg, #FF34DD 25.08%, #FFFFFF 43.64%, #3534FF 66.3%);
    //     -webkit-filter: blur(200px);
    //     -moz-filter: blur(200px);
    //     filter: blur(200px);
    //     transform: rotate(165deg);
    //     z-index: -1;
    // }

    // &::before {
    //     content: '';
    //     z-index: -1;
    //     position: absolute;
    //     width: 224px;
    //     height: 224px;
    //     left: 108px;
    //     top: 473px;
    //     background: linear-gradient(270deg, #FF34DD 25.08%, #FFFFFF 43.64%, #3534FF 66.3%);
    //     -webkit-filter: blur(200px);
    //     -moz-filter: blur(200px);
    //     filter: blur(200px);

    // }

    .banner-inner-wrapper-five {
        // &::after {
        //     content: '';
        //     z-index: -1;
        //     position: absolute;
        //     width: 292px;
        //     height: 292px;
        //     left: 1313px;
        //     top: 485px;
        //     background: linear-gradient(270deg, #FF34DD 25.08%, #FFFFFF 43.64%, #3534FF 66.3%);
        //     -webkit-filter: blur(200px);
        //     -moz-filter: blur(200px);
        //     filter: blur(200px);

        //     @media #{$laptop-device} {
        //         display: none;
        //     }

        //     @media #{$smlg-device} {
        //         display: none;
        //     }
        // }
    }
}



@-moz-document url-prefix() {
    .rts-banner-area-five {
        // &::after {
        //     filter: blur(200px);
        //     opacity: .3;
        // }

        // &::before {
        //     filter: blur(200px);
        //     opacity: .3;
        // }

        // .banner-inner-wrapper-five {
        //     &::after {
        //         filter: blur(200px);
        //         opacity: .3;
        //     }
        // }
        .round-shape-one {
            img {
                opacity: .15;
            }
        }
    }
}

.pagination-navigation-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .navigation {

        .swiper-button-next,
        .swiper-button-prev {
            height: 48px;
            width: 48px;
            background: #EBEBFF;
            display: flex;
            align-items: center;
            justify-content: center;

            i {
                color: var(--color-primary);
                transition: .3s;
            }

            &::after {
                display: none;
            }

            &:hover {
                background: var(--color-primary);
                transition: .3s;

                i {
                    color: #fff;
                }
            }
        }

        .swiper-button-prev {
            left: 0;
        }

        .swiper-button-next {
            right: auto;
            left: 58px;
        }
    }



    .pagination-2 {

        position: absolute;
        left: auto !important;
        right: 0 !important;

        .swiper-pagination {
            position: relative;

            @media #{$large-mobile} {
                display: none;
            }

            .swiper-pagination-bullet {
                display: inline-block;
                width: 87px;
                height: 4px;
                border-radius: 1px;
                margin-left: 16px;
            }
        }
    }
}

.button-area-right-header {
    display: flex;
    align-items: center;
    gap: 20px;

    .menu-btn-toggle {
        display: none;

        @media #{$mdsm-layout} {
            display: block;
        }
    }
}

.header-area-four {
    .button-area-right-header {
        display: none;

        @media #{$mdsm-layout} {
            display: block;

            svg {
                rect {
                    fill: #fff;
                }
            }
        }
    }

    .nav-area {
        @media #{$mdsm-layout} {
            display: none;
        }
    }
}

.header-area-four.style-five {
    .menu-btn-toggle {
        svg {
            rect {
                fill: #0B0A33;
            }
        }
    }
}


.menu-item-open {
    &>a {
        color: #5956FF !important;

        i {
            &::before {
                content: "\f077";
            }
        }
    }

    li a {
        &.active {
            color: var(--color-primary);
        }
    }
}

.with-megamenu .submenu .single-menu li .industries.active {
    color: var(--color-primary);
}