// service area start
.title-center-style-two {
    text-align: center;
}

.title-center-style-two {
    .title {
        font-weight: 400;

        @media #{$large-mobile} {
            font-size: 24px;
        }
    }
}

.single-service-style-two {
    background: #F6F6F6;
    padding: 48px 32px;
    transition: .3s;
    border-top: 3px solid #E7E7E7;

    .inner {
        overflow: hidden;
        height: 184px;
        display: flex;
        flex-direction: column;

        .icon {
            margin-bottom: 114px;
            transition: .3s;

            img {
                transition: .3s;
            }
        }

        .bottom {
            transition: .3s;

            .title {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 28px;
                font-weight: 400;
                transition: .3s;

                @media #{$large-mobile} {
                    font-size: 18px;
                }

                svg {
                    path {
                        stroke: #262626;
                    }
                }

                img {
                    transition: .3s;
                }
            }
        }
    }

    &:hover {
        background: #3534FF;
        border-color: #2A2ACC;

        & * {
            color: #fff;
        }

        svg {
            path {
                stroke: #fff !important;
            }
        }

        .inner {
            .icon {
                margin-bottom: 45px;

                img {
                    filter: brightness(0) saturate(100%) invert(99%) sepia(5%) saturate(2%) hue-rotate(32deg) brightness(104%) contrast(100%);
                }
            }

            .title {
                img {
                    filter: brightness(0) saturate(100%) invert(99%) sepia(5%) saturate(2%) hue-rotate(32deg) brightness(104%) contrast(100%);
                }
            }
        }
    }
}

.bg-dark-1 {
    background: #262626;

    .title-main-wrapper-center-three {
        .title {
            font-size: 48px;

            @media #{$large-mobile} {
                font-size: 34px;
            }
        }

        & * {
            color: #fff;
        }
    }
}

.single-service-three {
    padding: 48px;

    &.bg-light-3 {
        background: #3D3D3D;
    }

    .icon {
        margin-bottom: 60px;
        height: 48px;
        width: 48px;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
    }

    .title {
        font-size: 24px;
    }

    & * {
        color: #fff;
    }
}

.mt-dec-service-3 {
    margin-top: -134px;
    position: relative;
    z-index: 10;
}

.single-service-area-4 {
    border: 1px solid #D1D1D1;
    padding: 48px 32px;
    background: #FFFFFF;
    transition: .3s;
    height: 100%;

    &.in-about-page {
        box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
        border: none;
        border-top: 3px solid var(--color-primary);
        height: 100%;

        @media #{$sm-layout} {
            height: 100%;
        }

        .icon {
            margin-bottom: 80px;
        }

        .title-area {
            .title {
                font-size: 28px;
                margin: 0;
            }

            img {
                max-width: 28px;
                max-height: 28px;
            }

        }
    }

    .icon {
        margin-bottom: 148px;

        img {
            transition: .3s;
        }
    }

    .title-area {
        a {
            .title {
                transition: .3s;
                margin-bottom: 0;
            }
        }

        svg {
            path {
                stroke: #262626 !important;
            }
        }
    }

    .title-area a {
        display: flex;
        align-items: center;
        justify-content: space-between;

        a {
            &:hover {
                .title {
                    color: var(--color-primary);
                }
            }
        }
    }

    &:hover {
        background: var(--color-primary);
        border-color: var(--color-primary);

        .icon {
            img {
                filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(6deg) brightness(103%) contrast(102%);
            }
        }

        & * {
            color: #fff;
        }

        svg {
            path {
                stroke: #fff !important;
            }
        }
    }
}

.bg-4 {
    background: #F5F5FF;
}

.title-style-4-center {
    text-align: center;

    .title {
        font-size: 40px;
        font-weight: var(--font-medium);

        @media #{$large-mobile} {
            font-size: 30px;
        }
    }
}

.single-service-area-wrapper {
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 32px 24px;
    background: #FFFFFF;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    transition: .3s;
    height: 100%;

    img {
        transition: .3s;
    }

    .info {
        flex-basis: 80%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title {
            margin: 0;
            transition: .3s;
        }
    }

    svg {
        path {
            transition: .3s;
        }
    }

    &:hover {
        background: var(--color-primary);
        transform: translateY(-5px);

        & * {
            color: #fff;
        }

        svg {
            path {
                stroke: #fff;
            }
        }

        img {
            filter: brightness(0) saturate(100%) invert(98%) sepia(15%) saturate(0%) hue-rotate(259deg) brightness(118%) contrast(100%);
        }
    }
}

.service-list-between {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media #{$sm-layout} {
        flex-direction: column;
        align-items: flex-start;
        gap: 30px;
    }

    .left {
        max-width: 480px;

        span {
            color: #908FFF;
            font-weight: 600;
            display: block;
            margin-bottom: 25px;
            font-size: 28px;
        }

        .title {
            font-size: 64px;

            @media #{$large-mobile} {
                font-size: 44px;
            }
        }
    }

    .right-area-thumbnail {
        max-width: 500px;
        margin-left: auto;
        height: 500px;

        @media #{$sm-layout} {
            margin-left: 0;
            width: 100%;
            max-width: 100%;
            height: auto;
        }

        img {
            @media #{$sm-layout} {
                width: 100%;
            }
        }
    }
}

.single-service-border-top {
    padding-top: 45px;
    border-top: 2px solid rgba(0, 0, 0, 0.1);

    .icon {
        margin-bottom: 30px;
    }
}

.bg-gradient-5 {
    background: #F5F5FF;
}

.bg-gradient-5-bold {
    background: #EBEBFF;
}

.has-dropdown {
    &:hover {
        .nav-link {
            color: var(--color-primary);
        }
    }
}

.rts-service-provide-area {
    background: #F6F6F6;
}

.container-s-float {
    max-width: 1484px;
    margin: auto;
}

.single-service-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 45px 110px;
    transition: .3s;

    @media #{$smlg-device} {
        padding: 45px 50px;
    }

    @media #{$mdsm-layout} {
        flex-direction: column;
        align-items: flex-start;
        gap: 25px;
    }

    @media #{$large-mobile} {
        padding: 35px 25px;
    }

    &.active {
        background: #FFFFFF;
    }

    &:hover {
        background: #FFFFFF;
    }

    .title {
        font-size: 40px;

        @media #{$sm-layout} {
            line-height: 1.4;
            font-size: 32px;
        }
    }

    p.disc {
        max-width: 640px;
        font-size: 20px;
        line-height: 1.5;
    }

    .icon {
        height: 96px;
        width: 96px;
        background: #3534FF;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .arrow-btn {
        border: 1px solid #D1D1D1;
        height: 48px;
        width: 48px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .3s;

        img {
            transition: .3s;
            filter: brightness(0) saturate(100%) invert(19%) sepia(71%) saturate(7461%) hue-rotate(244deg) brightness(100%) contrast(102%);
        }

        &:hover {
            background: var(--color-primary);
            border-color: var(--color-primary);

            img {
                filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(79deg) brightness(105%) contrast(102%);
            }
        }
    }

}

.thumbnail-bannr-service-right {
    height: 500px;

    @media #{$mdsm-layout} {
        height: auto;
    }
}

.large-image-area-bg-service-page {
    background-image: url(../images/service/05.webp);
    height: 750px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @media #{$large-mobile} {
        height: 550px;
    }
}

.exparts-area-main-mt-dec {
    background: #fff;
    padding: 80px;
    margin-top: -80px;
    position: relative;
    z-index: 10;

    @media #{$large-mobile} {
        padding: 80px 20px;
    }

    .title {
        font-size: 48px;

        @media #{$mdsm-layout} {
            font-size: 36px;
        }

        @media #{$large-mobile} {
            font-size: 32px;
        }
    }

    .title-area-center-inner-with-sub {
        max-width: 75%;

        @media #{$mdsm-layout} {
            max-width: 100%;
        }

        .rts-btn {
            margin: auto;
            margin-top: 40px;

            svg {
                path {
                    stroke: #fff;
                }
            }
        }
    }
}

.rts-btn.btn-primary {
    svg {
        max-width: 24px;

        path {
            stroke: #fff;
        }
    }

    &:hover {
        svg {
            path {
                stroke: #2A2ACC !important;
            }
        }
    }
}

.service-area-details-wrapper {
    .inner-content {
        position: relative;
        z-index: 5;
        background: #fff;
    }

    .top {
        text-align: center;
        margin: auto;
        margin-top: -220px;
        padding-top: 80px;
        max-width: 60%;

        @media #{$smlg-device} {
            max-width: 100%;
        }

        p.disc {
            max-width: 60%;
            margin: auto;

            @media #{$large-mobile} {
                max-width: 90%;
            }
        }
    }

    .mid-content {
        padding: 130px 100px;

        @media #{$sm-layout} {
            padding: 120px 50px;
        }

        @media #{$large-mobile} {
            padding: 80px 20px;
        }

        @media #{$small-mobile} {
            padding: 70px 15px;
        }


        p.disc {
            font-size: 24px;
            line-height: 1.5;

            @media #{$smlg-device} {
                font-size: 18px;
            }
        }
    }
}

.thumbnail-area-large-service-detaile-mid {
    height: 700px;
    clip-path: polygon(0% 5%, 100% 0%, 100% 95%, 0% 100%, 0% 5%);

    @media #{$smlg-device} {
        height: auto;
    }
}

.service-area-details-wrapper.border-bottom {
    border: none !important;

    .inner-content {
        border-bottom: 1px solid #D1D1D1;
    }
}

.float-right-style {
    width: 124%;
    max-width: 124%;

    @media #{$large-mobile} {
        width: 100%;
    }
}

.innovative-solution {
    position: relative;

    .shape-top-right {
        position: absolute;
        top: -24px;
        left: 242px;
    }
}

.luminos-main-solutioin-key {
    .title {
        font-size: 36px;
        line-height: 1.3;

        @media #{$sm-layout} {
            font-size: 28px;
        }
    }

    .check-wrapper {
        margin-top: 30px;
    }

    .single-check {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 18px;

        p {
            margin: 0;
            font-size: 16px;
            font-weight: 500;
        }
    }

    .tag-wrapper {
        display: flex;
        align-items: center;
        gap: 15px;
        margin-top: 70px;

        @media #{$sm-layout} {
            margin-top: 30px;
            flex-wrap: wrap;
        }

        .single-tag {
            cursor: pointer;

            span {
                display: block;
                padding: 5px 10px;
                border-radius: 22px;
                border: 1px solid #0F62FE;
                color: var(--color-primary);
                transition: .3s;
            }

            &:hover {
                span {
                    color: #fff;
                    background: var(--color-primary);
                }
            }
        }
    }
}


.single-working-process-choose-us {
    padding: 40px;
    border: 1px solid #D1D1D1;
    margin-bottom: 22px;
    border-radius: 10px;
    transition: .3s;

    @media #{$sm-layout} {
        padding: 15px;
    }

    &:hover {
        border-color: var(--color-primary);
    }

    .title {
        font-size: 24px;
        font-weight: 500;
    }

    p {
        margin-bottom: 31px;
    }

    .tag-wrapper {
        display: flex;
        align-items: center;
        gap: 15px;

        @media #{$sm-layout} {
            flex-wrap: wrap;
        }

        .single {
            display: block;
            padding: 5px 15px;
            border: 1px solid #D1D1D1;
            border-radius: 33px;
            transition: .3s;
            cursor: pointer;

            &:hover {
                background: var(--color-primary);
                color: #fff;
                border: 1px solid var(--color-primary);
            }
        }
    }
}

.index-five {
    overflow-x: visible;
}

.service-sticky-wrapper-main {
    height: auto;
}

.service-section-area {
    position: sticky;
    top: 30px;

    @media #{$laptop-device} {
        top: 0;
    }
}