.accordion-container-one {
    max-width: 768px;
    margin: auto;

    .accordion-item {
        border-color: #D1D1D1;
    }

    .accordion-body {
        padding: 20px 35px;
        padding-top: 10px;
    }

    .accordion-button {
        box-shadow: none;
        padding: 26px 32px;
        font-size: 20px;
        color: #000;

        @media #{$large-mobile} {
            font-size: 16px;
            padding: 20px;
        }

        &::after {
            transform: rotate(92deg);
        }

        &[aria-expanded="true"] {
            background: transparent;

            &::after {
                transform: rotate(0);
            }
        }
    }
}

.bg-primary {
    background: var(--color-primary);
}

.title-between-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media #{$sm-layout} {
        flex-direction: column;
        align-items: flex-start;
        gap: 25px;
    }

    @media #{$large-mobile} {
        flex-direction: column;
        align-items: flex-start;
    }

    p.disc {
        max-width: 35%;

        @media #{$sm-layout} {
            max-width: 100%;
        }

        @media #{$large-mobile} {
            max-width: 100%;
        }
    }
}

.bg-primary .title-between-wrapper * {
    color: #fff;
}

.professional-faq-area {
    overflow: hidden;

    .shape-top {
        position: absolute;
        left: 0;
        bottom: -3px;

        @media #{$sm-layout} {
            display: none;
        }
    }
}

.accordion-container-one.style-two {
    .accordion-item {
        background: #F6F6F6;
        margin-bottom: 16px;
        border-top: 1px solid rgba(0, 0, 0, .125) !important;
    }

    .accordion-button {
        background-color: #F6F6F6;
    }
}