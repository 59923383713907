// mega menu style here
.header-style-one {
    position: relative;
}

.with-megamenu {
    .submenu {
        width: 100%;
        padding: 20px;
        border-top: 1px solid #f1f1f1 !important;

        .single-menu {
            padding: 0;
            flex-direction: column;
            align-items: flex-start;
            gap: 5px;
            padding: 45px 0;

            li {
                display: block;
                width: 100%;
                margin-bottom: 8px;

                a {
                    display: block;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    font-family: var(--font-primary);

                    &:hover {
                        gap: 15px;
                    }
                }
            }

            .single-service-area-wrapper {
                display: flex;
                align-items: flex-start;
                box-shadow: none;
                background: #fff;
                padding: 6px 0px !important;

                img {
                    transition: 0.3s;
                    width: 35px;
                    height: auto;
                }

                .info {
                    flex-direction: column;
                    align-items: flex-start;

                    .title {
                        font-size: 20px;
                        font-family: var(--font-medium);
                        transition: .3s;
                        padding-bottom: 5px;
                    }

                    p {
                        font-weight: 400;
                        font-family: var(--font-primary);
                        max-width: 90%;
                        color: #1d1d1db3;
                    }
                }

                &:hover {
                    transform: none;

                    .info {
                        .title {
                            color: var(--color-primary);
                        }
                    }

                    img {
                        filter: none;
                    }

                    * {
                        color: var(--color-body);
                    }
                }
            }

            .parent-top-industry {
                p {
                    margin-bottom: 0;
                    font-size: 20px;
                    font-family: var(--font-medium);
                }
            }

            li {
                .industries {
                    padding: 5px 0 !important;
                    display: flex;
                    align-items: center;
                    gap: 7px;
                    color: var(--color-body);

                    svg {
                        path {
                            stroke: #6D6D6D;
                        }
                    }

                    &:hover {
                        background: none;
                        color: var(--color-primary);
                    }
                }
            }
        }
    }
}

.single-menu.industry-signle-menu {
    li {
        margin-bottom: 1px !important;

        a {
            font-family: var(--font-primary);
            font-weight: 400 !important;
        }
    }
}

.single-menu.industry-signle-menu {
    padding: 45px !important;
    height: 100%;
    position: relative;
    z-index: 1;

    &::after {
        background: #f7f7f7;
        content: "";
        left: 0;
        height: 100%;
        width: 10000%;
        top: 0;
        bottom: 0;
        position: absolute;
        z-index: -1;
    }
}

.has-dropdown {
    .submenu.with-border {
        padding: 6px 0 12px 0;

        li {
            a {
                border-bottom: 1px solid #f7f7f7;
                font-family: var(--font-primary);
            }

            &:last-child {
                a {

                    border: none;
                }
            }
        }
    }
}

.with-megamenu.margin-single-0 .submenu .single-menu li {
    display: block;
    width: 100%;
    margin-bottom: 0;
}