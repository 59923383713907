.rts-btn {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    max-width: max-content;
    transition: .3s;
    z-index: 1;

    &:focus {
        box-shadow: none;
    }

    img {
        max-width: 24px;
        height: auto;
    }

    &.btn-bold {
        height: 48px;
    }

    &.btn-border {
        border: 1px solid #D1D1D1;
        gap: 44px;
        color: #262626;
        font-weight: 400;
        position: relative;
        overflow: hidden;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            // border: 1px solid #D1D1D1;
            transition: opacity 0.5s, border 0.5s;
        }

        &::after {
            content: "";
            position: absolute;
            top: 1%;
            left: 0%;
            width: 200px;
            height: 200px;
            background-color: #3534FF;
            border-color: transparent;
            border-radius: 50%;
            transform: translate(-10px, -70px) scale(0.1);
            opacity: 0;
            z-index: -1;
            transition: transform 0.5s, opacity 0.5s, background-color 0.5s;
        }

        &:hover {
            color: #fff;
            border: 1px solid transparent;

            ::before {
                opacity: 0;
            }

            &::after {
                opacity: 1;
                transform: scaleX(1.5) scaleY(1.5);
            }

            img {
                filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(136deg) brightness(120%) contrast(107%);
            }
        }
    }

    &.btn-primary {
        padding: 17px 15px;
        background: var(--color-primary);
        gap: 31px;
        height: 48px;
        position: relative;
        overflow: hidden;
        border: 1px solid var(--color-primary);

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            // border: 1px solid #D1D1D1;
            transition: opacity 0.5s, border 0.5s;
        }

        &::after {
            content: "";
            position: absolute;
            top: 1%;
            left: 0%;
            width: 200px;
            height: 200px;
            background-color: #fff;
            border-color: transparent;
            border-radius: 50%;
            transform: translate(-10px, -70px) scale(0.1);
            opacity: 0;
            z-index: -1;
            transition: transform 0.5s, opacity 0.5s, background-color 0.5s;
        }

        &:hover {
            color: var(--color-primary);
            // border: 1px solid transparent;

            ::before {
                opacity: 0;
            }

            &::after {
                opacity: 1;
                transform: scaleX(1.5) scaleY(1.5);
            }

            svg {
                path {
                    fill: var(--color-primary) !important;
                    stroke: var(--color-primary);
                }
            }
        }
    }
}

.arrow-btn {
    img {
        max-width: 22px;
        height: auto;
    }
}


.btn-border {
    svg {
        max-width: 24px;

        path {
            stroke: #262626;
        }
    }

    &:hover {
        svg {
            path {
                stroke: #fff;
            }
        }
    }
}