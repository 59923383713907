.container {
    max-width: 1290px;
    margin: auto;

    @media #{$smlg-device} {
        padding: 0 15px;
    }

    @media #{$mdsm-layout} {
        padding: 0 15px;
    }
}

.bg_nutral {
    background: #F6F6F6;
}

.float-right-style {
    width: calc(100% + 320px);
}

.bg-banner-three {
    background: #262626;
}

.title-main-wrapper-center-three {
    text-align: center;
    max-width: 785px;
    margin: auto;

    .title {
        font-size: 64px;

        @media #{$smlg-device} {
            font-size: 46px;
        }

        @media #{$mdsm-layout} {
            font-size: 42px;
        }

        @media #{$sm-layout} {
            font-size: 32px;
        }

        @media #{$large-mobile} {
            font-size: 28px;
        }
    }

    p {
        font-size: 20px;
    }
}

.title-area-center-inner-with-sub {
    text-align: center;
    max-width: 768px;
    margin: auto;

    span {
        display: block;
        max-width: max-content;
        margin: auto;
        border: 1px solid #4948FF;
        padding: 4px 14px;
        border-radius: 33px;
        position: relative;
        z-index: 1;

        &::after {
            position: absolute;
            content: "";
            left: 0;
            top: -8.5%;
            bottom: 0;
            right: 0;
            width: 104%;
            height: 130%;
            z-index: -1;
            background: linear-gradient(91deg, rgba(255, 255, 255, 0) 0%, #f8f9fa 100%);
        }
    }

    .title {
        font-size: 64px;
        margin-bottom: 18px;
        margin-top: 13px;

        @media #{$sm-layout} {
            font-size: 44px;
            line-height: 1.3;
        }

        @media #{$large-mobile} {
            font-size: 36px;
            line-height: 1.3;
        }
    }

    p.disc {
        font-size: 20px;
        line-height: 1.5;
    }
}




.header-style-one {
    &.border-bottm {
        border-bottom: 1px solid #D1D1D1;
    }
}


// preloader for this template

.loader-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;

    .loader-section {
        position: fixed;
        top: 0;
        background: var(--color-white);
        width: 50%;
        height: 100%;
        z-index: 1000;
    }
}

.loader-wrapper .loader-section.section-left {
    left: 0
}

.loader-wrapper .loader-section.section-right {
    right: 0;
}

/* Loaded Styles */
.loaded .loader-wrapper .loader-section.section-left {
    // transform: translateX(-100%);
    transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1.000);
    opacity: 0;
}

.loaded .loader-wrapper .loader-section.section-right {
    // transform: translateX(100%);
    transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1.000);
    opacity: 0;
}

.loaded .loader {
    opacity: 0;
    transition: all 0.3s ease-out;
}

.loaded .loader-wrapper {
    visibility: hidden;
    transform: translateY(-100%);
    transition: all .3s 1s ease-out;
}

.loader:after {
    content: "";
    position: absolute;
    top: 14px;
    left: 14px;
    right: 14px;
    bottom: 14px;
    border: 4px solid transparent;
    border-top-color: var(--color-primary);
    border-right-color: var(--color-primary);
    border-bottom-color: var(--color-primary);
    border-radius: 100%;
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;
}

.loader {
    display: block;
    position: relative;
    top: 50%;
    left: 50%;
    width: 70px;
    height: 70px;
    z-index: 1001;
    transform: translate(-50%, -50%);
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.rtl-ltr-switcher-btn {
    position: fixed;
    right: 0;
    top: 55%;
    left: auto;
    transform: translateY(-50%);
    // transform: rotate(-90deg);
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    padding: 25px 6px;
    background: linear-gradient(-45deg, #7274ff, #3c9ae7, var(--color-primary), #001aff);
    background-size: 400% 400%;
    animation: gradient 5s ease infinite;
    z-index: 1000;
    color: #fff;
    cursor: pointer;

    .ltr,
    .rtl {
        display: none;

        &.show {
            display: block;
        }
    }

    @keyframes gradient {
        0% {
            background-position: 0% 50%;
        }

        50% {
            background-position: 100% 50%;
        }

        100% {
            background-position: 0% 50%;
        }
    }
}