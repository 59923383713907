.title-between-wrapper {
    .title {
        font-weight: 400;
        font-size: 64px;

        @media #{$sm-layout} {
            font-size: 44px;
        }

        @media #{$large-mobile} {
            font-size: 32px;
        }
    }
}

.single-testimonials-area-one {
    background: #fff;
    padding: 48px;
    border-right: 1px solid #D1D1D1;
    border-bottom: 1px solid #D1D1D1;

    @media #{$large-mobile} {
        padding: 22px;
    }

    p {
        font-size: 20px;
        line-height: 1.5;

        @media #{$large-mobile} {
            font-size: 16px;
        }
    }

    .author-wrapper {
        display: flex;
        align-items: center;
        gap: 15px;
        margin-top: 60px;

        @media #{$large-mobile} {
            margin-top: 30px;
        }

        .avatar {
            img {
                max-width: 60px;
            }
        }

        .information {
            .title {
                margin-bottom: 5px;
                font-size: 16px;
            }

            span {
                font-size: 14px;
                color: #636363;
            }
        }
    }
}

.testimonials-area-start {
    .shape-top-right {
        position: absolute;
        right: 120px;
        top: -24px;
    }
}

.rts-blog-area {
    .shape-bottom {
        position: absolute;
        top: 0;
        right: 0;

        @media #{$large-mobile} {
            display: none;
        }
    }
}

.cta-one-wrapper {
    position: relative;

    .shape-area {
        position: absolute;
        left: 80px;
        top: -24px;
    }
}

.testimonials-title-two-center {
    text-align: center;

    .title {
        color: #fff;
        font-weight: 400;
        font-weight: 64px;
    }
}


.swiper-slide-active {
    .single-testimonials-two {
        border-left: 1px solid #4A4965;
    }
}

.single-testimonials-two {
    padding: 48px;
    border-right: 1px solid #4A4965;
    border-top: 1px solid #4A4965;
    border-bottom: 1px solid #4A4965;

    @media #{$large-mobile} {
        padding: 20px;
    }

    & * {
        color: #fff;
    }

    p {
        font-size: 20px;
        line-height: 1.5;

        @media #{$large-mobile} {
            font-size: 18px;
        }
    }

    .inner-author {
        display: flex;
        align-items: center;
        gap: 16px;
        margin-top: 120px;

        @media #{$large-mobile} {
            margin-top: 50px;
        }

        .info {
            .title {
                font-weight: 500;
                margin-bottom: 6px;
                font-size: 16px;
            }

            span {
                font-size: 14px;
            }
        }
    }
}

.rts-testimonials-area-two {
    position: relative;

    .shape-area-one {
        position: absolute;
        left: 120px;
        top: -24px;
    }

    .shape-area-two {
        position: absolute;
        right: 0px;
        top: 0px;
    }
}

.position-relative-testimonials-two {
    position: relative;

    .swiper-button-prev,
    .swiper-button-next {
        height: 48px;
        width: 48px;
        border-radius: 50%;
        background: #323153;
        display: flex;
        align-items: center;
        justify-content: center;
        bottom: 0;
        top: auto;
        transition: .3s;

        &:hover {
            background: var(--color-primary);
        }

        &::after {
            display: none;
        }

        i {
            color: #fff;
        }

    }

    .swiper-button-prev {
        left: 45.8%;
        right: auto;

        @media #{$large-mobile} {
            left: 36.5%;
        }

        @media #{$small-mobile} {
            display: none;
        }
    }

    .swiper-button-next {
        right: 45.8%;
        left: auto;

        @media #{$md-layout} {
            right: 42.8%;
        }

        @media #{$large-mobile} {
            right: 36.5%;
        }

        @media #{$small-mobile} {
            display: none;
        }
    }
}

.mySwiper-testimonials {
    padding-bottom: 130px;

    @media #{$small-mobile} {
        padding-bottom: 0;
    }

}

.single-testimonials-4 {
    padding: 48px;
    border: 1px solid #4A4965;

    @media #{$large-mobile} {
        padding: 8px;
    }

    & * {
        color: #fff;
    }

    .user-area {
        display: flex;
        align-items: center;
        gap: 15px;
        margin-top: 65px;

        @media #{$large-mobile} {
            margin-top: 30px;
        }

        .title {
            margin-bottom: 3px;
        }
    }
}

.mySwiper-testimonials-4 {
    padding: 60px 0;
    padding-bottom: 150px;
    position: relative;

    @media #{$mdsm-layout} {
        padding-bottom: 50px;
    }

    @media #{$sm-layout} {
        padding-bottom: 50px;
    }

    .swiper-wrapper {
        display: flex;
        align-items: center;
    }

    .swiper-slide {
        transition: .3s ease-out;
        transition-delay: .3s;
    }

    .swiper-slide-active {
        transform: scale(1.3);

        @media #{$mdsm-layout} {
            transform: scale(1) !important;
        }

        .single-testimonials-4 {
            background: #201F99;
            border: 1px solid #201F99;

            .user-area {
                margin-top: 120px;

                @media #{$large-mobile} {
                    margin-top: 40px;
                }
            }
        }
    }

    .swiper-button-next,
    .swiper-button-prev {
        height: 48px;
        width: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #323153;
        border-radius: 50%;
        position: absolute;
        top: auto;
        bottom: 0;
        transition: .3s;

        @media #{$mdsm-layout} {
            display: none;
        }

        &::after {
            font-size: 16px;
            color: #FFFFFF;
        }

        &:hover {
            background: var(--color-primary);
        }
    }

    .swiper-button-next {
        left: auto;
        right: 47%;
        bottom: 0;

        @media #{$laptop-device} {
            right: 45%;
        }

        @media #{$smlg-device} {
            right: 45%;
        }
    }

    .swiper-button-prev {
        right: auto;
        left: 47%;
        bottom: 0;

        @media #{$laptop-device} {
            left: 45%;
        }

        @media #{$smlg-device} {
            left: 45%;
        }
    }
}

.testimonails-title-wrapper-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    .swiper-button-next,
    .swiper-button-prev {
        height: 48px;
        width: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #454545;
        transition: .3s;

        @media #{$large-mobile} {
            display: none;
        }

        &::after {
            display: none;
        }

        &:hover {
            background: var(--color-primary);
        }
    }

    .swiper-button-next {
        right: 0;
    }

    .swiper-button-prev {
        right: 48px;
        left: auto;
    }
}

.mySwiper-testimonials-5 {
    padding-bottom: 100px;

    @media #{$large-mobile} {
        padding-bottom: 60px;
    }

    .swiper-pagination-bullet {
        width: 40px;
        border-radius: 0;
        height: 4px;
        background: #454545;
        opacity: 1;
    }

    .swiper-pagination-bullet-active {
        width: 40px;
        border-radius: 0;
        height: 4px;
        background: var(--color-primary);
        opacity: 1;
    }
}

.rts-testimonials-area-about.bg-dark-1 {
    & * {
        color: #fff;
    }
}

.single-testimonials-about {
    padding: 48px;
    background: #3D3D3D;

    @media #{$sm-layout} {
        padding: 25px;
    }

    p.disc {
        font-size: 20px;
        line-height: 1.5;
        margin-bottom: 45px;
    }

    .author-area {
        display: flex;
        align-items: center;
        gap: 14px;

        .information {
            .title {
                margin-bottom: 7px;
                font-size: 16px;
            }

            p {
                font-size: 14px;
                color: #999999;
            }
        }
    }
}

.testimonials-border {
    .single-testimonials-area-one {
        border: 1px solid #d1d1d1;
    }
}

.testimonials-area-start.in-service-single {
    .title-between-wrapper {
        .title {
            font-size: 48px;

            @media #{$large-mobile} {
                font-size: 32px;
            }
        }
    }
}