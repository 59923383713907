.single-team-style-one {
    text-align: center;

    .thumbnail {
        overflow: hidden;
    }

    .inner-content {
        margin-top: 30px;

        .title {
            font-size: 24px;
            margin-bottom: 8px;
            transition: .3s;

            &:hover {
                color: var(--color-primary);
            }
        }

        .deg {
            font-size: 16px;
            margin-bottom: 0;
        }
    }
}

.title-team-left {
    .title {
        font-size: 48px;

        @media #{$large-mobile} {
            font-size: 34px;
        }
    }
}

.team-single-banner-area {
    .single-team-content {
        .title {
            font-size: 56px;
            margin-bottom: 25px;

            @media #{$large-mobile} {
                font-size: 36px;
            }
        }

        p {
            margin-bottom: 25px;
        }
    }

    .counter-main-wrapper {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-top: 45px;

        @media #{$large-mobile} {
            flex-wrap: wrap;
        }

        .single {
            padding: 20px 24px;
            border: 1px solid #EAF0FF;
            border-radius: 5px;
        }

        .title {
            font-size: 40px;
            margin-bottom: 10px;
        }
    }

    .social-area-wrapper {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-top: 20px;

        p {
            padding: 0;
            margin: 0;
            font-family: var(--font-medium);
        }

        .social-one-area {
            display: flex;

            ul {
                display: flex;
                align-items: center;
                list-style: none;
                padding: 0;
                margin: 0;
                gap: 13px;
            }
        }
    }
}